import { graphql, useStaticQuery } from "gatsby";
import { getCurrentRichContent } from "../helpers/richContentHelpers";

export const infinityCommonSubpageTplData = (id) => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage {
          edges {
            node {
              id
              template {
                ... on WpTemplate_InfinityCommonSubpage {
                  infinityCommonRichContent {
                    fieldGroupName

                    # SECTION: Hero
                    hero {
                      category
                      header
                      secondHeader
                      text
                      buttons {
                        button {
                          variant
                          link {
                            target
                            title
                            url
                          }
                        }
                      }
                      image {
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData(
                              width: 1800
                              placeholder: BLURRED
                              quality: 100
                            )
                          }
                        }
                      }
                    }

                    # SECTION: Cards
                    cards {
                      category
                      title
                      description
                      button {
                        variant
                        link {
                          target
                          title
                          url
                        }
                      }
                      items {
                        item {
                          title
                          description
                          background {
                            ...wpImageFluid
                          }
                          button {
                            variant
                            link {
                              target
                              title
                              url
                            }
                          }
                          icon {
                            ...wpImageFluid
                          }
                          options {
                            iconSize
                            iconPosition
                            color
                            backgroundColor
                            spanRows
                            spanCols
                            fullHeight
                          }
                        }
                      }
                      options {
                        display
                        cols
                        gap
                        textAlign
                        itemContentTextAlign
                        justify
                        align
                        justifyContent
                        alignContent
                      }
                    }

                    # SECTION: Image section
                    imageSections {
                      imageSection {
                        category
                        title
                        text
                        slug
                        variant
                        image {
                          ...wpImageFluid
                        }
                        items {
                          category
                          title
                          text
                          button {
                            variant
                            link {
                              target
                              title
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return getCurrentRichContent(data, id, "infinityCommonRichContent");
};
